import '@src/static/fonts/fonts.css';
//One with 99% Config
export const onClientEntry = async () => {
  if (!('IntersectionObserver' in window)) {
    await import('intersection-observer');
  }
};

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production' || typeof gtag !== 'function') {
    return;
  }

  // Send a page view event when the route changes
  const pagePath = location?.pathname + location.search + location.hash;
  gtag('event', 'page_view', { page_path: pagePath });
};

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

//Old Config

// export const onClientEntry = async () => {
//   // Polyfill IntersectionObserver for older browsers
//   if (!('IntersectionObserver' in window)) {
//     await import('intersection-observer');
//   }
// };

// export const onRouteUpdate = ({ location }) => {
//   if (process.env.NODE_ENV !== 'production') {
//     return;
//   }

//   // Ensure gtag is defined before tracking page views
//   if (typeof gtag === 'function') {
//     const pagePath = `${location?.pathname || ''}${location.search ||
//       ''}${location.hash || ''}`;
//     gtag('event', 'page_view', { page_path: pagePath });
//   }
// };

// export const onServiceWorkerUpdateReady = () => {
//   // Prompt user for a page refresh when a service worker update is available
//   const userConfirmed = window.confirm(
//     'A new version of this site is available. Reload to update?'
//   );
//   if (userConfirmed) {
//     window.location.reload();
//   }
// };

//Oldest Config

// export const onClientEntry = async () => {
//   if (typeof IntersectionObserver === `undefined`) {
//     await import(`intersection-observer`);
//   }
// };

// export const onRouteUpdate = ({ location }) => {
//   if (process.env.NODE_ENV !== 'production') {
//     return null;
//   }

//   const pagePath = location
//     ? location.pathname + location.search + location.hash
//     : undefined;
//   setTimeout(() => {
//     if (typeof gtag === 'function') {
//       gtag('event', 'page_view', { page_path: pagePath });
//     }
//   }, 100);
// };

// export function onServiceWorkerUpdateReady() {
//   window.location.reload(true);
// }
