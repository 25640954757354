module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"gatsby-remark-autolink","maintainCase":true,"removeAccents":true,"offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Seelay","short_name":"Seelay","start_url":"/","description":"Multi-skilled Product Designer from India, Passionate about blending creativity with data to shape intuitive, future-ready products that align with both people's needs and business goals.","background_color":"#FFFFFF","theme_color":"#FF2222","display":"standalone","icon":"src/static/s-logo-red-maskable.svg","lang":"en","orientation":"portrait-primary","screenshots":[{"src":"/darkmode.webp","sizes":"1280x720","type":"image/webp","platform":"narrow","label":"Dark Mode"},{"src":"/lightmode.webp","sizes":"1280x720","type":"image/webp","platform":"narrow","label":"Light Mode"}],"categories":["portfolio","design","development","art","opensource"],"shortcuts":[{"name":"Open Blog","short_name":"Blog","description":"Read some blogs.","url":"/blog","icons":[{"src":"/icon-blog.webp","sizes":"192x192","type":"image/webp"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8030257915c7b3a652c21a80db4dbc3b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff2222","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
